import { Stack } from '../../types/stratus';
export const GoogleTagManager = {
  gtm: function (accountID) {
    // Config instructions available at https://developers.google.com/tag-platform/tag-manager/web
    const script =
      `
      (function(w,d,s,l,i){w[l]=w[l]||[];
        w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
        var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
        j.async=true;
        j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
        f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','` +
      accountID +
      `');`;

    const noScript =
      `
      <iframe src=https://www.googletagmanager.com/ns.html?id='` +
      accountID +
      `'
      height="0" width="0" style="display:none;visibility:hidden">
      </iframe>`;

    return {
      script,
      noScript
    };
  },
  createElement: function (accountID) {
    // Create the script and noscript DOM elements
    const script = () => {
      const script = document.createElement('script');
      script.innerHTML = this.gtm(accountID).script;
      return script;
    };

    const noScript = () => {
      const noscript = document.createElement('noscript');
      noscript.innerHTML = this.gtm(accountID).noScript;
      return noscript;
    };

    return {
      script,
      noScript
    };
  },
  // Initialize GTM with the accountID and optional dataLayer push, if any data is to be pushed on init (e.g. user ID).
  init: function (stack, data) {
    let accountID;
    switch (stack) {
      case Stack.dev:
      case Stack.pie:
        accountID = 'GTM-KQPXDXT';
        break;
      case Stack.stage:
        accountID = 'GTM-WVXPJXP';
        break;
      case Stack.prod:
        accountID = 'GTM-TMN7QMH3';
        break;
    }

    // Add a dataLayer push
    if (data) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push(data);
    }

    // Only insert GTM DOM elements if not already present
    // They need to be inserted at the top
    const existingScript = document.querySelector(
      'script[src*="www.googletagmanager.com"]'
    );

    if (!existingScript) {
      document.head.insertBefore(
        this.createElement(accountID).script(),
        document.head.childNodes[0]
      );
      document.body.insertBefore(
        this.createElement(accountID).noScript(),
        document.body.childNodes[0]
      );
    }
  }
};
