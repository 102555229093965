import { GoogleTagManager } from '../assets/static/googleTagManager';

// Event data layer for Google Tag Manager
const eventDataLayer = (stack: string, { event, clientID, ...args }) => {
  GoogleTagManager.init(stack, {
    event: event,
    date: new Date().toUTCString(),
    clientID,
    ...args
  });
};

export default eventDataLayer;
